/**
 * Created by jerry on 2020/03/30.
 * 储值卡经手人 api
 */
import * as API from '@/api/index'

export default {
    // 获取储值卡经手人设置列表
    getSavingCardSaleHandler: params => {
        return API.POST('api/savingCardSaleHandler/all', params)
    },
    // 创建储值卡经手人设置
    createSavingCardSaleHandler: params => {
        return API.POST('api/savingCardSaleHandler/create', params)
    },
    // 更新储值卡经手人设置
    updateSavingCardSaleHandler: params => {
        return API.POST('api/savingCardSaleHandler/update', params)
    },
    // 调整储值卡经手人设置顺序
    moveSavingCardSaleHandler: params => {
        return API.POST('api/savingCardSaleHandler/move', params)
    },
}